import React from 'react'
import livroTudoTemPq from '../Assets/livro-tudotempq.webp'
import Stopwatch from '../Components/Stopwatch';
import ContainerOffer from '../Components/ContainerOffer';

function BookEp1() {
  return (
    <section className='padding-100 flex-center flex-column flex__gap-50 bg-red'>
        <div className='flex-center'>
            <Stopwatch />
        </div>
        <div className='flex-center flex__gap-100 content-book'>
            <ContainerOffer 
            priceOriginal='R$ 99,90'
            priceOffer='R$60,90'
            priceKindle='R$ 34,90'
            />
            <div className='img img-350 img-book'>
                <img src={livroTudoTemPq} alt='livro Tudo tem um Porquê - Episódio 2: O Despertar' />
            </div>
        </div>
    </section>
  )
}

export default BookEp1
