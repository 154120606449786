import React from 'react'
import '../Styles/Episode.css'
import ButtonDefault from '../Components/ButtonDefault'
import livroTudoTemPq from '../Assets/livro-tudotempq.webp'
import livroDespertar from '../Assets/livro-odespertar.webp'


function Episode() {
  return (
    <section id='episode-section' className='flex-center flex-column flex__gap-50 background-dark padding-100'>
        <div id='content-episode' className='flex-center flex__gap-50'>
            <div>
                <h2 className='title-episode'>EPISÓDIO 1 - O ENCONTRO</h2>
                <p className='description-episode'>Lesly Maia, uma especialista em recursos humanos, mantém um relacionamento seguro com Adam. Porém, sua vida muda drasticamente com a chegada de Victor Reis, um chef solitário e perturbado pelo desaparecimento de seu pai. Envolvida com Victor, Lesly questiona suas relações e desejos, enquanto é atormentada por sonhos e memórias obscuras.</p>
            </div>
            <div id='img-container-episode'>
                <div id='img-pq' className='img img-300 img-episode'>
                    <img src={livroTudoTemPq} alt='Livro Tudo tem um Porquê'/>
                </div>
                <div id='img-despertar' className='img img-250 img-episode'>
                    <img src={livroDespertar} alt='O Despertar'/>
                </div>
            </div>
            <div>
                <h2 className='title-episode'>EPISÓDIO 2 - O DESPERTAR</h2>
                <p className='description-episode'>Como você se sentiria se descobrisse que tudo o que acreditou ser verdadeiro é uma mentira? No Episódio 2: O despertar, você acompanhará os personagens em uma jornada para uma vida inimaginável.
                Esse despertar trará consequências inesperadas para o quarteto amoroso, mudando suas vidas para sempre. Descubra quão sombrias e duvidosas podem ser as relações interpessoais e o quanto desconhecemos sobre nós mesmos.</p>
            </div>
        </div>
        <div id='ep3-container' className='flex-center flex-column text-center half-screen'>
            <h2 className='title-episode'>EPISÓDIO 3 - RECOMEÇAR</h2>
            <p className='description-episode'>Como você se sentiria se descobrisse que tudo o que acreditou ser verdadeiro é uma mentira? No Episódio 2: O despertar, você acompanhará os personagens em uma jornada para uma vida inimaginável.
            Esse despertar trará consequências inesperadas para o quarteto amoroso, mudando suas vidas para sempre. Descubra quão sombrias e duvidosas podem ser as relações interpessoais e o quanto desconhecemos sobre nós mesmos.</p>
        </div>
        <ButtonDefault text="Quero ler agora" />
    </section>
  )
}

export default Episode
