import React from 'react';
import '../Styles/Book.css'
import livroDespertar from '../Assets/livro-odespertar.webp'
import Stopwatch from '../Components/Stopwatch';
import ContainerOffer from '../Components/ContainerOffer';

function BookEp2() {

  return (
    <section id='bookep2' className='padding-100 flex-center flex-column flex__gap-50'>
        <div className='flex-center'>
            <Stopwatch />
        </div>
        <div id='offer2' className='flex-center flex__gap-100'>
            <div className='img img-300 img-book'>
                <img src={livroDespertar} alt='livro Tudo tem um Porquê - Episódio 2: O Despertar' />
            </div>
            <ContainerOffer 
            priceOriginal='R$ 99,90'
            priceOffer='R$58,90'
            priceKindle='R$ 9,90'
            link="https://www.amazon.com.br/Tudo-porqu%C3%AA-Crislaine-Zuca-Polvere/dp/6525452287/ref=tmm_pap_swatch_0?_encoding=UTF8&dib_tag=se&dib=eyJ2IjoiMSJ9.pPmaRYd1J3kqkckH6wE7uw.xS_IwxNafrN51xDceLyLCsTIfpVFMMrHSR7YUzMErxE&qid=1733751911&sr=1-1"
            />
        </div>
    </section>
  )
}

export default BookEp2
