import React from 'react'
import livroRecomeco from '../Assets/livro-recomeco.webp'
import Stopwatch from '../Components/Stopwatch';
import ContainerOffer from '../Components/ContainerOffer';

function BookEp3() {
  return (
    <section id='bookep3' className='padding-100 flex-center flex-column flex__gap-50 bg-red'>
        <div className='flex-center'>
            <Stopwatch />
        </div>
        <div className='flex-center flex__gap-100 content-book'>
            <ContainerOffer 
            priceOriginal='R$ 92,90'
            priceOffer='R$ 56,90'
            priceKindle='R$ 9,90'
            link='https://www.amazon.com.br/Tudo-Tem-Porqu%C3%AA-Epis%C3%B3dio-Recome%C3%A7ar-ebook/dp/B0DFMTNKB4/ref=tmm_kin_swatch_0?_encoding=UTF8&dib_tag=se&dib=eyJ2IjoiMSJ9.qgXtB-ILXeCaVy9LUJYvJw.TXH9cp6gCXSu-6rpPwDsi0KShk8Tk6XPPibj2WKyw0k&qid=1736253374&sr=8-1'
            />
            <div className='img img-400 img-book'>
                <img src={livroRecomeco} alt='livro Tudo tem um Porquê - Episódio 2: O Despertar' />
            </div>
        </div>
    </section>
  )
}

export default BookEp3
